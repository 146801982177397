<template>
  <div class="index gameLobby" >
    <div class="inx-top">
      <div class="c">
          <span class="l"></span>
          <span class="r"></span>
        </div>
    </div>
    <div class="lobby-sl">
      <SetLanguage></SetLanguage>
    </div>
    <div class="index-main" >

      <div :class="showLogin ? 'inx-hd loginbox': 'inx-hd'" >
        <div class="avatar-l" v-show="!showLogin">
          <img :src="userInfo.avatar"/>
        </div>

        <div v-show="!showLogin" class="btn-r" >
          <span>{{$t('withdrawal.gameAccount')}}</span>
          <div style="display: flex;">
            <strong>
              {{ $filters.currencySymbol(userInfo.availableAmount) }}
            </strong>
            <div style="margin-left: 10px;">
              <nut-button size="small" plain type="info" style="min-width: 75px;height: 25px;padding:0;" @click="rechargeClick">
                {{$t('home.recharge')}}
              </nut-button>
              <nut-button size="small" type="info"  class="ml5" style="min-width: 75px;height: 25px;padding:0;" @click="withdrawalClick('coin')">
                {{$t('home.withdrawal')}}
              </nut-button>
            </div>
          </div>
        </div>
        <div v-show="showLogin && loadingCompleted" class="top-login">
          <div class="lobby-register"  @click="registerClick">
            <strong  v-if="commonInfo.registerReward === 0">
              {{ $t('global.SIGN_UP_BONUS_3')}}
              <span>{{ $filters.currencySymbol(commonInfo.firstRechargeGift) }}</span>
            </strong>
            <strong v-else>
              {{ $t('global.SIGN_UP_BONUS_1')}}
              <span>{{ $filters.currencySymbol(commonInfo.registerReward) }}</span>
              {{ $t('global.SIGN_UP_BONUS_2')}}
            </strong>
          </div>
          <p class="lobby-login" @click="userLoginClick">
            {{ $t('global.ALREADY_HAVE_AN_ACCOUNT') }}
            <span>{{ $t('userLogin.signIn')}}</span>
          </p>
        </div>

      </div>

      <div class="inx-body" ref="mainScroll">

        <div class="link-btn" v-show="!showLogin">
          <div class="btn l" @click="toMyHome"><span>VIP</span><strong>Lv{{ userInfo.vipLevel }}</strong></div>
          <div class="btn c" @click="toAgentPromotion"><span>{{ $t('global.invite')}}</span><strong>{{userInfo.inviteCode}}</strong></div>
          <div class="btn r" @click="toTaskReward"><span>{{ $t('global.reward')}}</span><strong>Go</strong></div>
        </div>

        <h4>
          {{$t('global.hotGame')}}
          <div v-if="country === 'in' && template === 's1'" class="parity-btn" @click="toTelegram">
            <img src="../assets/images/bg312.png" @click="toTelegram"/>
          </div>
        </h4>
        <div class="link-2x2" v-if="imgUrl">

          <div  v-for="(item, index) in games" :key="index"  class="link-i" @click="toGameHome(item.name)">
            <img v-if="item.name === 'crash'" :src="require('../assets/images/' + imgUrl + 'gm1.png')" />
            <img v-if="item.name === 'parity'" :src="require('../assets/images/' + imgUrl + 'gm3.png')" />
            <img v-if="item.name === 'mine'" :src="require('../assets/images/' + imgUrl + 'gm2.png')" />
            <img v-if="item.name === 'wheel'" :src="require('../assets/images/' + imgUrl + 'gm4.png')" />
            <img v-if="item.name === 'andar'" :src="require('../assets/images/' + imgUrl + 'gm5.png')"/>
            <img v-if="item.name === 'dice'"  :src="require('../assets/images/' + imgUrl + 'gm7.png')"/>
            <div v-show="item.newGame" class="link-new"></div>
          </div>

          <div class="link-i" v-if="country === 'in' &&  games.length % 2 === 1">
            <img src="../assets/images/gm6.png" />
          </div>
        </div>

      </div>

      <div class="inx-fd">
        <h4 class="top">{{$t('global.top10')}}</h4>
        
        <div class="top_1_3">
          <div  v-for="(item, index) in winRank.slice(0,3)" :key="index"  :class="'t'+ index">
            <img :src="require('../assets/images/' + imgUrl + 'avatar/' + item.avatar.slice(item.avatar.length-6))"/>
            <span>{{ item.desensitizedMobile }}</span>
            <strong>{{ $filters.currencySymbol(item.winAmount) }}</strong>
          </div>
        </div>
        <div class="top_4_10">
          <ul>
            <li v-for="(item, index) in winRank" :key="index">
              <i>{{ index+1 }}</i>
              <img :src="require('../assets/images/' + imgUrl + 'avatar/' + item.avatar.slice(item.avatar.length-6))"/>
              <span>{{ item.desensitizedMobile }}</span>
              <strong>{{ $filters.currencySymbol(item.winAmount) }}</strong>
            </li>
          </ul>
        </div>
      </div>
      
    </div>

    <!--**************************************** tg 提醒 ****************************************-->
    <nut-popup pop-class="pop-vip pop-vip-lobby" v-model:visible="vipTgDialog" :z-index="100">
      <VipRules :myLevel="0" :popTitle="popTitle"></VipRules>
      <div class="bd">
        {{ $t('global.Prediction_Channel_Text') }}<br/>
      </div>
      <div class="btn" style="margin: 0;">
        <nut-button style="width: 48%;margin: 0 1%;" type="primary" @click="vipTgDialog = false">{{ $t('global.Got_It') }}</nut-button>
        <nut-button style="width: 48%;margin: 0 1%;" type="primary" @click="rechargeBtnClick">{{ $t('global.Upgrade') }}</nut-button>
      </div>
    </nut-popup>


    <!-- <nut-popup pop-class="pop-demo" v-model:visible="vipTgDialog">
        <div class="hd">{{ $t('global.Prediction_Channel') }}</div>
        <div class="bd">
          {{ $t('global.Prediction_Channel_Text') }}<br/>
        </div>
        <div class="btn" style="margin: 0;">
          <nut-button style="width: 48%;margin: 0 1%;" type="primary" @click="vipTgDialog = false">{{ $t('global.Got_It') }}</nut-button>
          <nut-button style="width: 48%;margin: 0 1%;" type="primary" @click="rechargeBtnClick">{{ $t('global.Upgrade') }}</nut-button>
        </div>
    </nut-popup> -->

  </div>
  <tabbar :activeIndex="tabbarActiveIndex"></tabbar>

  <div class="to-help" @click="toHelp()"  v-if="commonInfo.supportCenterOn"></div>
  <div class="to-foot" @click="toFoot()"></div>
</template>

<script>
import {reactive, toRefs, onMounted, getCurrentInstance} from "vue";
import {useRouter} from "vue-router";
import TabbarMain from "../components/TabbarMain.vue";
import SetLanguage from "../components/SetLanguage.vue";
import { Toast } from "@nutui/nutui";
import axios from "axios";
import {isBlank} from "@/assets/js/commonUtil";
import { getCountry,setLastTimeGameName } from "@/assets/js/environment";
import VipRules from "../components/VipRules2.vue";

export default {
  name: "gamesLobby",
  components: {
    tabbar: TabbarMain,
    SetLanguage: SetLanguage,
    VipRules: VipRules,
  },
  data() {
    return {
      country: '',
      games: '',
      commonInfo: '',
      template: '',
      target: 300,
      dicePreview: true,
      popTitle: this.$t('recharge.popTitle_1'),
      i18n:{
        loading: this.$t('global.loading'),
      }
    };
  },
  created() {
    this.country = getCountry()
    this.template = document.querySelector('body').getAttribute('s')
    this.commonInfo = JSON.parse(localStorage.getItem("commonInfo"))
    this.games = this.commonInfo.games

    for (const key in this.games) {
      if(this.games[key].name === 'dice'){
        this.dicePreview = false
      }
    }
    
    if(isBlank(localStorage.getItem('firstVisit')) && this.games.length > 1) {
      localStorage.setItem("firstVisit", 'lobby')
    }
    
  },
  mounted() {
    setLastTimeGameName('lobby')
  },
  methods: {
    toTelegram(){
      window.open(this.commonInfo.telegramChannelUrl,"_self");
    }
  },
  setup() {
    const _data = getCurrentInstance();
    const state = reactive({
      
      imgUrl: "",
      tabbarActiveIndex: 2,
      showLogin: true,
      loadingCompleted: false,
      userInfo: {
        mobile: "",
        inviteCode: "",
        avatar: "",
        vipLevel: "",
        hasPassword: "",
        vipTitle: "",
        vipBg: "",
        availableAmount:''
      },
      winRank: [],
      vipTgDialog: false,
    });
    const router = useRouter();
    const methods = {
      toFoot(){
        this.$nextTick(() =>{
          this.$refs.mainScroll.scrollIntoView({
            behavior: 'smooth',  
            block: 'end',  
            inline: 'nearest'  
          })
        })
      },

      rechargeBtnClick() {
        router.push({ path: '/rechargeBalance' });
      },
      toHelp() {
        router.push({ path: '/helpSupport' });
      },
      toGameHome(n){
        let gameName = n + 'Home'
        router.push({name: gameName});
      },
      toMyHome(){router.push({name: "myHome"});},
      toAgentPromotion(){router.push({name: "agentPromotion"});},
      toTaskReward(){router.push({name: "taskReward"});},
      rechargeClick() {router.push({name: "rechargeBalance"});},
      userLoginClick() {router.push({name: "userLogin"});},
      registerClick() {router.push({name: "userRegister"});},

      withdrawalClick(t) {
        router.push({
          path: 'withdrawalBalance',
					query: {
						amountType: t
					}
				})
      },
      textToast(msg) {
        Toast.loading(msg, {
          id: "loading",
          duration: 0,
          bgColor: "rgba(0, 0, 0, 0.9)",
          cover: true,
        });
      }
    };
    onMounted(() => {
      state.imgUrl = localStorage.getItem('countryImgUrl')
      methods.textToast(_data.data.i18n.loading);
      axios
        .get("/api/game/lobby", {})
        .then(function (res) {
          state.winRank = res.data.data.winRank
          if(localStorage.getItem("token")){
            state.showLogin = (!isBlank(res.data.data.user)) ? false : true
            state.userInfo.mobile = res.data.data.user.mobile;
            state.userInfo.inviteCode = res.data.data.user.inviteCode;
            state.userInfo.avatar = 
            require('../assets/images/' + state.imgUrl + 'avatar/' + res.data.data.user.avatar.slice(res.data.data.user.avatar.length-6))
            state.userInfo.vipLevel = res.data.data.user.vipLevel;
            state.userInfo.hasPassword = res.data.data.user.hasPassword;
            state.userInfo.availableAmount = res.data.data.fundAccount.availableAmount;
          }else{
            state.showLogin = true
          }
          Toast.hide("loading");
          state.loadingCompleted = true 
        })
        .catch(function (error) {
          console.log(error);
          Toast.hide("loading");
        })
    });
    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>

<style scoped>

.index{ min-width: 300px;max-width: 500px; margin: 0 auto;
  background: url(../assets/images/bg15.png) 0 0 repeat-y;background-size: 100% 100%;
  background:#260029 url(../assets/images/bg15_1225c.png) 0px -7px no-repeat !important; background-size: 100% auto !important;}
.in .index {background:#260029 url(../assets/images/bg15_1225c.png) 0px -10px no-repeat !important; background-size: 100% auto !important;}
.inx-top { position: fixed;top: 0; left: 0;right: 0;height: 30px;z-index: 2000;
  background:#260029 url(../assets/images/bg15_1225c.png) 0px -8px no-repeat !important; background-size: 100% auto !important;}
/* .in .inx-top{background:#260029 url(../assets/images/bg15c.png) 0px 15px no-repeat !important; background-size: 100% auto !important;} */
.in .inx-top{background:#260029 url(../assets/images/bg15_1225c.png) 0px 0 no-repeat !important; background-size: 100% auto !important;}


.inx-top .l { width: 45%;float: left; line-height: 28px; height: 26px; margin-left: 10px; padding-left: 22px;
  color: #fff;font-size: 14px;}
.inx-top .r {width: 10%; float: right;line-height: 26px;height: 26px;text-align: right;}
.inx-top .r i { width: 16px;height: 16px;line-height: 18px;border: 2px solid #fff; display: inline-block; font-weight: 600;
  border-radius: 20px;font-size: 16px;color: #fff;text-align: center;font-style: normal; margin: 3px 10px 0 0;opacity: .7;}
.inx-hd{ height: 70px; padding:55px 0px 0 20px; display: flex;}
.avatar-l{ width: 35px; height:35px; border-radius:50%; margin-top:0px; border: 2px solid #eab000;}
.avatar-l img{ width: 35px;}
.btn-r{margin-top: 0px; padding-left: 10px;}
.btn-r span{font-size: 12px; display: block;color: #fff; opacity: .8; line-height: 10px;}
.btn-r strong{ height: 30px;line-height: 30px; color: #fff; font-size: 20px; margin-bottom: 0px; display: block;}
.btn-r strong span{vertical-align: middle;display: inline-block; height: 16px;}
.btn-r strong span .nut-icon{width: 25px; height: 9px; line-height: 20px;}
.inx-body{ margin: 10px; background: #fff url(../assets/images/bg149.png) 0 7px no-repeat; background-size:100% auto; 
  border-radius:10px;  padding: 10px 10px 5px 10px;margin-top:10px;}
.inx-body h4, .inx-fd h4{ margin: 0; padding: 10px 0 5px 30px; line-height: 20px; color: #000;
  background:url(../assets/images/bg99.png) left center no-repeat; background-size:25px; position: relative;}
.inx-body h4.new{background:url(../assets/images/bg100.png) left center no-repeat; background-size:30px;}
.inx-fd h4.top{background:url(../assets/images/bg101.png) left center no-repeat; background-size:27px;}
.inx-fd{ padding: 0 10px;}
.inx-fd h4{border-top: 0px; margin: 0 10px; color: #fff;}
.link-img{ margin-bottom: 5px; position: relative;}
.link-img img{ width: 100%;}

.link-btn{ background: linear-gradient(90deg, #af7213, #2e0143) !important;
  height: 60px; border-radius:12px; margin-bottom: 10px; margin-top: -30px; border-top:3px solid #efcc48; display: flex;}
.link-btn .btn{ width: 33%;height: 40px; margin-top: 8px; text-align: center; color: #fff;}
.link-btn .btn.c{ width: 33%; border-right:1px solid #7f515d;border-left:1px solid #ae711e; }
.link-btn .btn span{ padding-top: 30px; display: inline-block; margin-right: 5px; opacity: .4;}
.link-btn .btn strong{opacity: .8 }
.link-btn .btn.l{ background: url(../assets/images/bg96.png) center top no-repeat; background-size:26px;}
.link-btn .btn.c{ background: url(../assets/images/bg98.png) center top no-repeat; background-size:26px;}
.link-btn .btn.r{ background: url(../assets/images/bg97.png) center top no-repeat; background-size:26px;}
.inx-fd{ min-height: 200px; padding-bottom: 60px;}
.top_1_3{background: url(../assets/images/bg95.png) center bottom no-repeat;background-size:250px auto; 
  height: 148px; position: relative; overflow: hidden; width: 300px; margin: -15px auto 0 auto; }
.top_1_3 img{ width: 40px; height: 40px; border-radius:50%; }
.top_1_3 span{line-height: 20px; display: block; color: #fff;font-size: 10px;}
.top_1_3 strong{margin-top: 83px;display: block; color:#fff;font-weight: 300;}
.top_1_3 .t0 strong{margin-top: 96px;}
.top_1_3 .t2 strong{margin-top: 58px;}
.top_1_3 .t0{ width: 90px; text-align: center; position: absolute; top:0; left: 102px;}
.top_1_3 .t1{width: 90px; text-align: center; position: absolute; top:23px;left: 19px;}
.top_1_3 .t2{width: 90px; text-align: center; position: absolute; top:50px;left: 187px;}
.top_4_10{ min-height: 300px; background: #f2eefc;border-radius:20px; padding: 10px;}
.top_4_10 li{ background: #fff; border-radius:10px; padding: 10px; margin: 5px; line-height: 30px; display: flex;}
.top_4_10 li img{ width: 30px; height: 30px; border-radius:50%; margin: 0 10px;}
.top_4_10 li i{border-radius:10px; width: 20px; height: 20px; background: #ecc180; color: #fff; text-align: center;line-height: 20px; margin-top: 5px;}
.top_4_10 li span{ font-size: 12px; color: #6d64c5;}
.top_4_10 li strong{ margin-left: auto; color: rgb(189, 107, 0); font-weight: 300;}
.top_4_10 li:nth-child(1), .top_4_10 li:nth-child(2), .top_4_10 li:nth-child(3){ display: none;}
.top-login{ width: 100%; text-align: center; padding-top: 0px;}
.top-login .nut-button--small{font-size: 14px !important;}

.top-login .lobby-register{ width: 100%; height: 45px; line-height: 28px; text-align: center;
  background:url(../assets/images/bg301.png) center center no-repeat; background-size:auto 45px;
  width: 70%; margin: 0 auto; padding: 5px 20px; border-radius:5px; border: 0px solid #f7c929;}
.top-login .lobby-register strong{ color: #fff;}
.top-login .lobby-register strong span{color: #f7c929;}
.top-login .lobby-login{ margin: 0; line-height: 32px; color: #fff; font-weight: 600;
  text-shadow: 0 1px #000, 1px 0  #000, -1px 0  #000, 0 -1px #000;}
.top-login .lobby-login span{ color: #f7c929;}

.loginbox {height: 97px; padding: 70px 20px 0 20px;}
.language-btn{top:31px !important}

.link-2x2{ display: flex; justify-content: left; flex-wrap: wrap; justify-content: flex-start; margin: 10px 0 0 0; width: 100%;}
.link-2x2 .link-i{ width: 50%; position: relative;margin:10px 0 0 0}
.link-2x2 .link-i img{ width: 100%;}

.parity-btn{ width: 150px;  position: absolute; right: 0px !important; left: auto !important; top: 0px; z-index: 9;
  background: none;
  /* height: 35px;  border-radius:16px; background:#011f44 url(../assets/images/bg208.png)  center center no-repeat; background-size:140px auto; */
}
.parity-btn img{ width: 150px; height: auto;}
.coming-soon{ position: absolute; bottom: 22px; left: 0 ; right: 0;  text-align: center; height: 30px; line-height: 24px;
background: url(../assets/images/bg201.png) 0 0  no-repeat; background-size:100% 30px; color: #fff;font-size: 15px; font-weight: 600;}
.to-foot{ position: fixed; right: 10px; bottom: 50px; width: 45px; height: 45px;border-radius:10px;
  background:#e2d1f6 url(../assets/images/bg315.png) center 8px no-repeat; background-size:70% auto;}

  .to-help{position: fixed; right: 10px; bottom: 98px; width: 45px; height: 45px;border-radius:10px;
  background:#e2d1f6 url(../assets/images/bg303.png) center 5px no-repeat; background-size:80% auto;}
</style>
<style>

.btn-r .nut-button__warp{display: block; line-height: 23px;}
.pop-vip-lobby .popbd .p{ display: none !important;}
.pop-vip-lobby .bd{ padding: 10px; color: #872000;}
.pop-vip-lobby .btn{ padding: 0 0 20px 0;}
</style>
